import { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions } from 'keycloak-js';
import { IS_DEVELOPMENT_ENV, IS_TEST_ENV } from './EnvConfig';

export const keycloakConfig: KeycloakConfig = {
  url: 'https://auth.afbostader.se/',
  realm: IS_DEVELOPMENT_ENV ? 'diremoutv' : IS_TEST_ENV ? 'diremotest' : 'diremo',
  clientId: 'diremo-app',
};

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  enableLogging: IS_DEVELOPMENT_ENV,
  checkLoginIframe: false,
};

export const keycloakLoginOptions: KeycloakLoginOptions = {
  prompt: 'none',
};
