import { addToBrowserHistory } from 'helpers/utils';
import { CSSProperties } from 'react';
import { setCurrentRoute } from 'slices/navigation/navigationSlice';
import { useAppDispatch } from 'store/store';
import { useIsLargeDevice } from 'helpers/hooks/useIsLargeDevice';

interface Props extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  text?: string;
  children?: React.ReactNode;
  route?: string;
  onClick?: () => void;
}

const defaultStyle: CSSProperties = { cursor: 'pointer', userSelect: 'none' };

export default function AppLink({
  text,
  className,
  style,
  route = '',
  onClick,
  children,
  ...rest
}: Props) {
  const dispatch = useAppDispatch();
  const isLargeDevice = useIsLargeDevice();
  const isExternalRoute = route?.startsWith('http://') || route?.startsWith('https://');
  const href = isExternalRoute ? route : `/${route}`;

  const handleOnClick = (ev: React.MouseEvent) => {
    if (isExternalRoute) return;

    ev.preventDefault();

    if (route) {
      addToBrowserHistory(route);
      dispatch(setCurrentRoute({ route, isLargeDevice }));
    }

    if (onClick) onClick();
  };

  const combinedStyle: CSSProperties = { ...defaultStyle, ...style };

  return (
    <a
      href={href}
      className={className}
      style={combinedStyle}
      onClick={handleOnClick}
      {...rest}
      target={isExternalRoute ? '_blank' : '_self'}
    >
      {text}
      {children}
    </a>
  );
}

export const useAppLink = () => {
  const dispatch = useAppDispatch();
  const isLargeDevice = useIsLargeDevice();

  return (route: string, saveInBrowserHistory = true) => {
    if (saveInBrowserHistory) {
      addToBrowserHistory(route);
    }

    dispatch(setCurrentRoute({ route, isLargeDevice }));
  };
};
